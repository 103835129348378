import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ResetPassword from './components/Auth/ResetPassword';

function App(props) {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/auth/action" component={ResetPassword} />
          <Route path="*" render={() => <h1>Invalid url</h1>} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
