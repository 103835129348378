import { Card, CardContent, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import queryString from "query-string";
import React, { Component } from 'react';
import firebaseInstance from '../../firebase';
import Loader from "../Loader";
import MetaData from '../MetaData';

const useStyles = theme => ({
  card: {
    minWidth: 275,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
  },
  title: {
    fontWeight: "bold"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#008080",
    color: "#fff",
    '&:hover': {
      background: "#008080"
    },
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
});



export class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.params = queryString.parse(props.location.search)
    this.state = {
      email: "",
      newPassword: "",
      confirmPassword: "",
      errorMsg: { newPassword: "", confirmPassword: "" },
      loading: "",
      actionLoading: "",
      error: "",
    };
    this.mode = this.params.mode;
    this.oobCode = this.params.oobCode;
  }

  componentWillMount() {
    if (this.mode && this.oobCode) {
      this.setState({ loading: true })
      const auth = firebaseInstance.auth();
      switch (this.mode) {
        case "resetPassword":
          auth.verifyPasswordResetCode(this.oobCode).then((email) => {
            this.setState({ email: email, loading: false })
          }).catch((error) => {
            this.setState({ error: error.message || "Failed to verify oobCode", loading: false })
          });
          break;
        case 'recoverEmail':
          // Display email recovery handler and UI.
          this.handleRecoverEmail(auth);
          break;
        default:
      }
    }
  }

  handleRecoverEmail = (auth) => {
    // Localize the UI to the selected language as determined by the lang
    // parameter.
    let restoredEmail = null;
    // Confirm the action code is valid.
    auth.checkActionCode(this.oobCode).then((info) => {
      // Get the restored email address.
      restoredEmail = info['data']['email'];
      this.setState({ email: restoredEmail, loading: false })
      // Revert to the old email.
    }).catch((error) => {
      this.setState({ error: error.message, loading: false })
      // Invalid code.
    });
  }

  sendPasswordResetEmail = () => {
    const auth = firebaseInstance.auth();
    const { email } = this.state
    auth.applyActionCode(this.oobCode).then(() => {
      auth.sendPasswordResetEmail(email).then(() => {
        this.setState({ error: `Reset password link has been sent to email <b>${email}</b>`, actionLoading: false })
        // Password reset confirmation sent. Ask user to check their email.
      }).catch((error) => {
        this.setState({ error: error.message, actionLoading: false })
        // Error encountered while sending password reset code.
      });
    }).catch((error) => {
      this.setState({ error: error.message, actionLoading: false })
      // Error encountered while sending password reset code.
    });
  }

  submit = () => {
    this.setState({ actionLoading: true }, () => {
      if (this.mode === 'recoverEmail') {
        this.sendPasswordResetEmail();
      } else {
        const { newPassword, confirmPassword, errorMsg, email } = this.state;
        if (newPassword && confirmPassword && newPassword === confirmPassword && newPassword.length > 5) {
          const auth = firebaseInstance.auth();
          auth.confirmPasswordReset(this.oobCode, newPassword).then(() => {
            this.setState({ error: `Password updated successfully for email: <b>${email}</b>`, actionLoading: false })
            const { continueUrl } = this.params
            if (continueUrl)
              window.location.href = continueUrl
          }).catch((error) => {
            this.setState({ error: error.message, actionLoading: false })
          });
        } else {
          this.setState({ errorMsg: { ...errorMsg, newPassword: !newPassword ? "New password is required." : newPassword.length < 6 ? "Password should be at least 6 characters." : "", confirmPassword: confirmPassword !== newPassword ? "New password did not match." : !confirmPassword ? "Confirm password is reqired." : "" }, actionLoading: false })
        }
      }
    })
  }

  render() {
    const { loading, email, newPassword, confirmPassword, errorMsg, error, actionLoading } = this.state
    const classes = this.props.classes;
    const title = this.mode === 'resetPassword' ? `Reset password` : `Recover email`
    const buttonText = this.mode === 'resetPassword' ? `Reset` : `Recover`
    return (
      <Container component="main" maxWidth="xs">
        <MetaData title={title} />
        {loading ? <Loader /> : <div className={classes.paper}>
          <img className={classes.logo} src="https://insighttimer.com/static/media/insighttimer.944c6642.svg" alt="Insight Timer" />
          {error ? <Card className={classes.card}><CardContent><Typography component="h1" variant="h5" align="center" dangerouslySetInnerHTML={{ __html: error }}>
          </Typography></CardContent></Card> : <div style={{ width: "100%" }}>
            <Typography align="center" className={classes.title}>
              {title}
            </Typography>
            <form className={classes.form} noValidate >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                disabled
              />
              {this.mode === 'resetPassword' && <> <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                error={errorMsg.newPassword !== ""}
                value={newPassword}
                onBlur={(e) => this.setState({ errorMsg: { ...errorMsg, newPassword: !e.target.value ? "New password is required." : errorMsg.newPassword } })}
                onChange={(e) => {
                  this.setState({ newPassword: e.target.value, errorMsg: { ...errorMsg, newPassword: e.target.value.length < 6 ? "Password should be at least 6 characters." : "", confirmPassword: (confirmPassword.length > 0 && confirmPassword !== e.target.value) ? "New password did not match." : "" } })
                }}
              />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  error={errorMsg.confirmPassword !== ""}
                  value={confirmPassword}
                  onBlur={(e) => this.setState({ errorMsg: { ...errorMsg, confirmPassword: !e.target.value ? "Confirm Password is required." : newPassword !== confirmPassword ? "New password did not match." : "" } })}
                  onChange={(e) => {
                    this.setState({ confirmPassword: e.target.value, errorMsg: { ...errorMsg, confirmPassword: newPassword !== e.target.value ? "New password did not match." : "" } })
                  }}
                /></>}
              <div className={classes.alert}>
                {Object.keys(errorMsg).map((key, index) => {
                  return errorMsg[key] && <Alert severity="error" key={index}>{errorMsg[key]}</Alert>
                })
                }
              </div>
              <Button
                fullWidth
                variant="contained"
                className={classes.submit}
                onClick={this.submit}
                disabled={actionLoading}
              >
                {buttonText}
              </Button>
            </form>
          </div>}
        </div >}
      </Container >
    )
  }
}
export default withStyles(useStyles, { withTheme: true })(ResetPassword);

