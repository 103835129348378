import firebase from 'firebase';
import { getEnvVar } from './shared/config';

const firebaseConfig = {
    apiKey: getEnvVar("REACT_APP_API_KEY"),
    authDomain: getEnvVar("REACT_APP_AUTH_DOMAIN"),
    databaseURL: getEnvVar("REACT_APP_DATABASE_URL"),
    projectId: getEnvVar("REACT_APP_PROJECT_ID"),
    storageBucket: getEnvVar("REACT_APP_STORAGE_BUCKET"),
    messagingSenderId: getEnvVar("REACT_APP_MESSAGING_SENDER_ID"),
    appId: getEnvVar("REACT_APP_APP_ID")
};

const firebaseInstance = firebase.initializeApp(firebaseConfig);
export default firebaseInstance;
