import React from 'react';
import { Helmet } from "react-helmet";

export default function MetaData(props) {
  const { children, title } = props
  return (
    <div>
      <Helmet>
        <title>{`Insight Timer | ${title || ''}`}</title>
        {children}
      </Helmet>
    </div>
  )
}
